import { $EventBus } from "@/main";
import { mapActions, mapState } from 'vuex';
import { registerStatus } from '../../../store/services/register-status';
import Note from './note/Note.vue'
import moment from 'moment';
import ApexCharts from 'apexcharts';

export default {
  name: "InspectionReport",
  data: () => ({
    title: "",
    filters: {
      year: moment().format('YYYY'),
      management_id: null,
      service_id: null
    },
    filtersApply: {
      year: moment().format('YYYY'),
      management_id: null,
      service_id: null
    },
    filterNotes: {
      year: null,
      quarter: null,
      inspection_id: null,
      service_id: null,
      management_id: null
    },
    filterNoteDialog: {
      type: '',
      year: null,
      quarter: null,
      inspection_id: null,
      service_id: null,
      management_id: null
    },
    inspectionCode: "",
    menu: false,
    modal: false,
    menu2: false,
    breadcrumbs: [],
    headers: [],
    registerStatus: [...[{label: 'Todos los estados', value: ''}], ...registerStatus],
    loading: false,
    chartBar: null,
    years: [],
    overlay: false,
    managements_all: [],
    services: [],
    quarterNames: ['Primero', 'Segundo', 'Tercero', 'Cuarto'],
    dialog: false,
    isExpandPeriod: false,
  }),
  computed: {
    startDate() {
      return this.filters.year;
    },
    endDate() {
      return this.formatDate(this.filters.end_date);
    },
    ...mapState('inspection', ['inspection', 'report']),
    ...mapState('security', ['managements', 'costCenters']),
    ...mapState('noteQuarterService', ['notesQuarterService']),
  },
  created() {
    this.getDataManagementsAll();
  },
  mounted() {
    this.createLinkDocument = this.$route.path + "/create";
    $EventBus.$emit("showSubNav", false);
    this.initialize();
  },
  methods: {
    ...mapActions('inspection', ['find', 'getReport', 'cleanReport']),
    ...mapActions('security', ['getManagements']),
    ...mapActions('noteQuarterService', {getNotesQuarterService: 'list', resetQuarters: 'resetQuarters', findNoteQuarterService: 'find', resetQuarter: 'resetQuarter'}),
    getNotesQuarterServiceData(){
      this.getNotesQuarterService({
        success: null,
        fail: null,
        params: this.filterNotes
      });
    },
    getNoteQuarterServiceData(id){
      this.findNoteQuarterService({
        success: ()=>{ 
        this.dialog = true;
        },
        fail: ()=>{},
        id: id
      })
    },
    getDataManagementsAll(){
      this.getManagements({
        success: (response)=>{
          this.managements_all = response.data.managements
        },
        fail: ()=>{},
        params: {user_id: this.$store.state.auth.user.id}
      });
    },
    getDataManagements(){
      this.services = [];
      this.getManagements({
			success: () => {
				// this.managements.map(management => {
				//   this.services = [...this.services, ...management.costCenters];
				// });
				const found = this.managements.filter(
					(e) => e.id == this.filters.management_id
				);
				this.services = found && found[0] ? found[0]?.costCenters : [];
			},
			fail: () => {},
			params: {
				management_id: this.filters.management_id
					? this.filters.management_id
					: null,
				user_id: this.$store.state.auth.user.id,
			},
		});
    },
    getInspection(id){
      this.find({
        success: ()=>{ 
          this.setBreadCrumbs();
          this.setHeaders();
        },
        fail: ()=>{},
        id: id
      })
    },
    getReportData(id){
      this.overlay = true;
      this.getReport({
        success: ()=>{ 
          this.filtersApply = Object.assign({}, this.filters);
          this.resetQuarters();
          if(this.validateFiltersNote()){
            this.setFiltersNote(null)
            this.getNotesQuarterServiceData();
          }
          setTimeout(()=>{this.renderReport(this.getOptionsReport());}, 1000);
          this.overlay = false;
        },
        fail: ()=>{
          this.overlay = false;
        },
        id: id,
        filters: this.filters
      })
    },
    getLinkPdf(id){
      let link = ''
      this.pdf({
        success: (response)=>{
          link = response
        },
        fail: this.handleFail,
        id: id,
      })
      return link
    },
    initialize() {
      this.setYears();
    },
    getOptionsReport(){
      let months = this.report.months.map(month => month.name);
      let monthsShort = this.report.months.map(month => month.name.substring(0, 3));
      let data = this.report.months.map(month => parseFloat(month.items.compliance).toFixed(0));
      // APEX
      var options = {
        series: [{
          name: 'Valores',
          type: "column",
          data: data
        },
        {
          name: "Meta: 90%",
          type: "line",
          data: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90]
        }],
        chart: {
          type: 'line',
          height: 350,
          zoom: false,
          stackType: '100%'
        },
        colors:['#0039a6', '#ff7900'],
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
            dataLabels: {
              position: 'top'
            },
            borderRadius: 10,
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center'
        },
        dataLabels: {
          enabled: true,
          style: {
              colors: ['#A3A3A3', 'transparent'],
              fontSize: '12px',
              fontWeight: 'bold'
          },
          background: {
            enabled: false,
          },
          offsetY: -10,
          formatter: function(val) {
            return val + '%'
          },
        },
        stroke: {
          show: true,
          width: [0, 3],
          colors: ['#ff7900', '#ff7900']
        },
        xaxis: {
          categories: monthsShort,
        },
        yaxis: {
          title: {
            text: 'Cumplimiento por indicador (%)',
            style: {
              color: 'rgba(0, 0, 0, 0.87);',
              fontWeight: 'bold'
            },
          },
          min: 0,
          max: 100,
          labels: {
            formatter: function (val) {
                return val.toFixed(0) + '%'
            }
          }
        },
        fill: {
          opacity: 1,
          colors: ['#0039a6']
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + '%'
            }
          },
          x: {
            formatter: function(value) {
              return months[value -1];
            }
          }
        }
      };

      return options;
    },
    renderReport(options) {
      let chartElement = document.querySelector("#chart");
      if(chartElement){
        let chart = new ApexCharts(chartElement, options);
        chart.render();
      }
    },
    goRegisterView(id) {
      this.$router.push("/dashboard/inspections/registers/" + id);
    },
    setBreadCrumbs(){
      this.breadcrumbs = [
        {
          text: "Inspecciones",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "InspectionsList",
          },
        },
        {
          text: this.inspection ? this.inspection.name : '',
          disabled: true,
          href: "/",
        },
        {
          text: 'REPORTES',
          disabled: true,
          href: "/",
        },
      ];
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDateString(date){
      let dateString = ''
      if(date){
        let d = date.toString().substring(0, 10).split('-')
        dateString = `${d[2]}-${d[1]}-${d[0]}`
      }
      return dateString
    },
    setHeaders(){
      if(this.inspection){
        let addHeaders = [];
        this.headers = [
          {
            text: "Aréa de gerencia",
            value: "area_manager_name",
            align: "start",
            sortable: false,
            class: "primary--text",
          },
          {
            text: "Fecha",
            value: "date",
            sortable: false,
            class: "primary--text",
          }

        ];

        if(this.inspection.type == 'BODY-A'){
         addHeaders = [
          {
            text: "Cliente",
            value: "client_names",
            align: "start",
            sortable: false,
            class: "primary--text",
          },
          {
            text: "Responsable",
            value: "manager",
            sortable: false,
            class: "primary--text",
          }
         ];
        }
        else if(this.inspection.type == 'BODY-B'){
          addHeaders = [];
        }

        this.headers = [...this.headers, ...addHeaders];
        this.headers = [...this.headers, ...[
          {
            text: "Área/Taller/Estación",
            value: "area",
            align: "center",
            sortable: false,
            class: "primary--text",
          },
          {
            text: "Criterios aplicados",
            value: "itemsTotal",
            align: "center",
            sortable: false,
            class: "primary--text",
          },
          {
            text: "Aprobados",
            value: "itemsSi",
            align: "center",
            sortable: false,
            class: "primary--text",
          },
          {
            text: "Rechazados",
            value: "itemsNo",
            align: "center",
            sortable: false,
            class: "primary--text",
          },
          {
            text: "N/A",
            value: "itemsNa",
            align: "center",
            sortable: false,
            class: "primary--text",
          },
          {
            text: "Estado",
            value: "status",
            align: "center",
            sortable: false,
            class: "primary--text",
          },
          {
            text: "Acciones",
            value: "actions",
            sortable: false,
            class: "primary--text",
          },
          {
            text: "",
            value: "show",
            align: "end",
            sortable: false,
            class: "primary--text",
          }
        ]];
      }
    },
    loadData(id){
      this.getInspection(id);
    },
    setYears(){
      let year = moment().format('YYYY');
      let startYear = year - 50;
      for(startYear; startYear <= year; startYear++){
        this.years.push(startYear.toString());
      }
    },
    changeFilter(){
      this.getReportData(this.inspection.id);
    },
    filterService(item, queryText) {
      return (
        item.pep_code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    changeManagement(){
      this.filters.service_id = null
      this.getDataManagements()
    },
    setFiltersNote(quarter){
      this.filterNotes.year = this.filters.year;
      this.filterNotes.quarter = quarter;
      this.filterNotes.inspection_id = this.inspection.id;
      this.filterNotes.service_id = this.filters.service_id;
      this.filterNotes.management_id = this.filters.management_id;
    },
    validateFiltersNote(){
      return (this.filters.year && this.inspection && this.filters.management_id && this.filters.service_id);
    },
    getQuarterResult(quarterData){
      let result = '';
      if(this.notesQuarterService.length > 0){
        this.notesQuarterService.forEach( note => {
          if(quarterData.quarter.toString() == note.quarter.toString()){
            result = note.analysis_of_results;
          }
        } );
      }
      return result;
    },
    getQuarterAction(quarterData){
      let result = '';
      if(this.notesQuarterService.length > 0){
        this.notesQuarterService.forEach( note => {
          if(quarterData.quarter.toString() == note.quarter.toString()){
            result = note.improvement_actions;
          }
        } );
      }
      return result;
    },
    closeNote(status, action){
      this.resetFilterNoteDialog();
      this.dialog = status;
      if(action){
        this.getNotesQuarterServiceData();
      }
    },
    getNoteQuarterServiceId(quarterNumber){
      this.resetQuarter();
      let id = null;
      this.notesQuarterService.forEach( note => {
        if(note.quarter.toString() == quarterNumber){
          id = note.id;
        }
      });
      return id;
    },
    resetFilterNoteDialog(){
      this.filterNoteDialog.type = '';
      this.filterNoteDialog.year = null;
      this.filterNoteDialog.quarter = null;
      this.filterNoteDialog.inspection_id = null;
      this.filterNoteDialog.service_id = null;
      this.filterNoteDialog.management_id = null;
    },
    setFilterNoteDialog(quarterNumber, type){
      this.filterNoteDialog.type =type;
      this.filterNoteDialog.year = this.filterNotes.year;
      this.filterNoteDialog.quarter = quarterNumber;
      this.filterNoteDialog.inspection_id = this.filterNotes.inspection_id;
      this.filterNoteDialog.service_id = this.filterNotes.service_id;
      this.filterNoteDialog.management_id =this.filterNotes.management_id;
    },
    actionNote(quarterNumber, type){
      this.setFilterNoteDialog(quarterNumber, type);
      let noteQuarterServiceId = this.getNoteQuarterServiceId(quarterNumber);
      if(noteQuarterServiceId){
        this.getNoteQuarterServiceData(noteQuarterServiceId);
      }
      else{
      this.dialog = true;
      }
    }
  },
  components: {
    Note
  },
  destroyed() {
		this.cleanReport();
	},
  watch: {
    '$route': {
      handler: function(value) {
        if(value.name == 'InspectionReport'){
          this.loadData(value.params.id);
        }
      },
      deep: true,
      immediate: true
    }
  },
};
