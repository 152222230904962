import { mapActions, mapState } from 'vuex';
import { $EventBus } from "@/main";

export default{
  data () {
	return {
    noteText: '',
    overlayNote: false
	}
  },
  props: ['dialog', 'filters'],
  mounted() {
    $EventBus.$emit("showSubNav", false);
  },
  methods: {
    ...mapActions('noteQuarterService', {findNoteQuarterService: 'find', resetQuarter: 'resetQuarter', create: 'create'}),
    closeDialog(){
      this.$emit('close', false, false);
    },
    save(){
      this.create({
        success: (response) => {
          if(response.data.code === 200){
            this.noteText = '';
            $EventBus.$emit("showSnack", 'success', "Informacion guardad exitosamente.");
            this.$emit('close', false, true);
          }
          else{
            $EventBus.$emit("showSnack", 'error', "Algo salio mal, no se guardo la información.");
          }
        },
        fail: () => {},
        data: this.groupData()
      })
    },
    groupData(){
      let dataCreate = {
        id: this.noteQuarterService?this.noteQuarterService.id:null,
        year:this.filters.year.toString(),
        quarter:this.filters.quarter.toString(),
        inspection_id:this.filters.inspection_id,
        service_id:this.filters.service_id,
        management_id:this.filters.management_id,
        user_id: 1,
      };

      if(this.filters.type == 'analysis'){
        dataCreate['analysis_of_results'] = this.noteText;
      }
      else if(this.filters.type == 'actions'){
        dataCreate['improvement_actions'] = this.noteText;
      }

      return dataCreate;
    }
  },
  computed: {
    ...mapState('noteQuarterService', ['noteQuarterService']),
  },
  watch: {
    'dialog': function(newVal){
      if(newVal){
        if(this.noteQuarterService){
          if(this.filters.type == 'analysis'){
            this.noteText = this.noteQuarterService.analysis_of_results;
          }
          else if(this.filters.type == 'actions'){
            this.noteText = this.noteQuarterService.improvement_actions;
          }
        }
        else{
          this.noteText = ''
        }
      }
    }
  }
}